/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Images
import Plus from 'img/icon/plus.inline.svg'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Hero from 'components/shared/Hero'
import Breadcrumb from 'components/shared/Breadcrumb'
import ShadowBox from 'components/shared/ShadowBox'
import Quotes from 'components/shared/Quotes'
import Partners from 'components/shared/Partners'

// Third Party
import styled from 'styled-components'
import parse from 'html-react-parser'

export const Pro = styled.div`
  p {
    font-weight: ${props => props.theme.font.weight.xl};
    font-size: ${props => props.theme.font.size.xm};
    line-height: 24px;
    margin-bottom: 0;
  }

  svg {
    height: 40px;
    width: 40px;
  }
`

export const Work = styled.div`
  h2 {
    font-size: ${props => props.theme.font.size.xml};
    font-weight: ${props => props.theme.font.weight.xl};
    padding-bottom: 20px;
  }

  p {
    font-size: ${props => props.theme.font.size.sm};
    line-height: 22px;
  }

  a {
    color: ${props => props.theme.color.face.contrast} !important;
  }
`

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      ...SolutionsFrag
    }
  }
`

const PageTemplate = ({
  data: {
    page: { title, path, acf, yoast_meta: yoast },
  },
  pageContext
}) => {
  return (
    <Layout>
      <SEO
        yoast={yoast}
        path={path}
        image={{ url: acf.banner.image.localFile && acf.banner.image.localFile.childImageSharp.openGraph.src }}
      />

      <Hero className="mb-lg-4" content={acf.banner} />

      <Breadcrumb data={title} className="py-2" pageContext={pageContext} />

      <div className="container py-5">
        <div className="row py-4">
          <div className="col-lg-6 d-flex justify-content-center justify-content-lg-start">
            <ShadowBox delay={0.4} content={acf.content} />
          </div>
          <div className="col-lg-6 d-flex align-items-center justify-content-center">
            <div className="pb-5 pl-lg-5 pt-lg-0 pt-5">
              {acf.content.pros.map(pro => (
                <Pro className="d-flex align-items-center pb-5">
                  <Plus className="mr-3" />
                  {parse(pro.pro)}
                </Pro>
              ))}
            </div>
          </div>
        </div>

        <div className="row pt-lg-5 pb-5">
          <Work className="col-lg-5 py-4">
            {parse(acf.content.work_everywhere)}
          </Work>
        </div>
      </div>

      <Partners content={acf.content.relation} className="pb-5 mb-5" />

      <Quotes className="mb-5" content={acf.content.quote} />

    </Layout>
  )
}

export default PageTemplate
